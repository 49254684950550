@import '../../../sass/bootstrap/variables';

.cycler {
  @media (min-width: $screen-xs-min) {
    height: 200px;
  }
  @media (min-width: $screen-sm-min) {
    height: 350px;
  }
  @media (min-width: $screen-md-min) {
    height: 500px;
  }
  @media (min-width: $screen-lg-min) {
    height: 550px;
  }

  height: 200px;
  position: relative;
  overflow: hidden;
}

.crossfade {
  position: absolute;
  width: 100%;
  height: 100%;
  background: no-repeat center center;
  background-size: cover;
  object-fit: cover;
  // -webkit-transition: opacity 1s ease-in-out;
  // -moz-transition: opacity 1s ease-in-out;
  transition: opacity 3s ease-in-out, transform 10s;
}
