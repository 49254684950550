@import '../../sass/bootstrap/variables';

.title {
  position: relative;
  li a {
    color: white;
  }
  .title-image {
    position: relative;
    width: 100%;
    height: 575;
  }

  .title-text {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    margin: 10px;
    top: 10px;
    position: relative;
  }
}

.titleBase {
  padding: 20px 15px;
  //position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 120px;
  color: white;

  @media (min-width: $screen-md-min) {
    background-color: $brand-primary;
  }
}

.searchContainer {
  // color: $gray-darker;
  color: white;

  label {
    margin-top: 10px;
  }
  .search-box {
    margin-top: 10px;
    position: relative;
  }
  .search-submit {
    margin-top: 10px;
  }
}

.searchButton {
  margin-top: 5px;
  @media (min-width: $screen-md-min) {
    margin-top: 35px;
  }
}

.searchLabel {
  color: $brand-primary;
  @include tablet {
    color: inherit;
  }
}
