@import '../../sass/bootstrap/variables';

.mapContainer {
  height: 500px;
  position: relative;
}

.mapKey {
  background: $content-bg;
  color: $gray-dark;
  z-index: 20000;
  right: 0;
  position: absolute;
  padding: 10px;
}

.keyIcon {
  width: 32px;
}
