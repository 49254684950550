@import '../../sass/bootstrap/variables';

.item {
  position: relative;
  margin: 3px 5px;
  img {
    width: 100%;
  }
}

.homeImage {
  position: relative;
  margin-top: 2px;
  margin-bottom: 2px;
  // min-height: 250px;

  &.large {
    min-height: 400px;
    @include mobile {
      min-height: 200px;
    }
    @include tablet-mini {
      min-height: 360px;
    }
    @include tablet {
      min-height: 470px;
    }
    @include desktop {
      min-height: 570px;
    }
  }

  .overlay {
    i {
      background-color: transparentize($brand-primary, 0.3);
      color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
      opacity: 0;
      -webkit-transform: translatez(0);
      -webkit-transition: opacity 0.5s ease-in-out;
      -o-transition: opacity 0.5s ease-in-out;
      transition: opacity 0.5s ease-in-out;

      display: -webkit-box !important;
      display: -moz-box !important;
      display: -ms-flexbox !important;
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
    }

    p {
      margin: 0;
    }

    &:hover i {
      opacity: 1;
      text-decoration: none;
    }

    .caption {
      opacity: 1;
      background: rgba(darken($brand-primary, 10%), 0.8);
      bottom: 0;
      left: 0;
      position: absolute;
      width: 100%;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-left: 15px;
      color: white;
    }
  }
}

.icon {
  float: right;
  .chevron {
    vertical-align: middle;
    margin-right: 10px;
  }
}
